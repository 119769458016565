import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Link, { navigate } from '../Link'
import * as currenciesActions from '../../store/actions/currenciesActions'
import { createTheme } from '@mui/material/styles'
import { useStaticQuery, graphql } from 'gatsby'
import makeStyles from '@mui/styles/makeStyles'
import { Title } from '../../components/Core'
import { Spinner } from 'react-bootstrap'
import { fetchUserBalances } from '../../store/actions/userActions'
import WithTheme from '../../helpers/WithTheme'

const MUIDataTable = loadable(() => import('mui-datatables'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))

function createData(
  rank,
  currencyLogo,
  code,
  price,
  balanceAmountTotal,
  balanceInUserCurr,
  balanceCurrName,
  balanceCurrSlug
) {
  return {
    rank,
    currencyLogo,
    code,
    price,
    balanceAmountTotal,
    balanceInUserCurr,
    balanceCurrName,
    balanceCurrSlug,
  }
}

const useStyles = makeStyles(theme => ({
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      //   backgroundColor:'red',
    },
  },
  centerHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //   backgroundColor:'red',
    },
  },
}))

const BalancesTable = () => {
  const dispatch = useDispatch()
  const { t, i18n, ready } = useTranslation(['tables'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const userCurrency = useSelector(state => state.user.selectedCurr)
  const currSign = useSelector(state => state.user.currSign)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const userBalances = useSelector(state => state.user.balances)
  const tableRows = useSelector(state => state.user.tableRows)

  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  useEffect(() => {
    dispatch(fetchUserBalances())
    // dispatch(fetchUserHistory())
  }, [dispatch])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const classes = useStyles()

  const columns = [
    {
      name: 'rank',
      label: ready && `${t('rank')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          style: {
            opacity: 0.6,
            width: '1rem',
          },
        }),
      },
    },
    {
      name: 'currencyLogo',
      label: `${t('logo')}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            paddingRight: 0,
            background: userDarkMode ? '#191B20' : 'white',
          },
        }),
        customHeadRender: ({ index, ...column }) => {
          return <TableCell key={index}></TableCell>
        },
      },
    },
    {
      name: 'balanceCurrName',
      label: `${t('name')}`,
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            opacity: 0.6,
          },
        }),
      },
    },
    {
      name: 'code',
      label: `${t('code')}`,
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            opacity: 0.6,
          },
        }),
      },
    },
    {
      name: 'price',
      label: `${t('price')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },

    {
      name: 'balanceAmountTotal',
      label: `${t('totalAvailable')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'balanceInUserCurr',
      label: `${t('balance')} ${userCurrency}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     }
        //      else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'balanceCurrName',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'balanceCurrSlug',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ]

  let data = []

  const options = {
    filter: false,
    download: true,
    print: false,
    // serverSide: true,
    // count: 100,
    rowsPerPageOptions: [10, 30, 50, 100],
    // onTableChange: (action, tableState) => {
    //     // console.log('ACTION', action, 'STATE', tableState)
    //     switch (action) {
    //         case "changePage":
    //             setPageNumber(tableState.page + 1);
    //         break;
    //         case "changeRowsPerPage":
    //             setPageSize(tableState.rowsPerPage);
    //         break;
    //         default:
    // 	        return;
    //     }
    // },
    textLabels: {
      body: {
        noMatch: completed ? (
          t('noMatchCurrencies')
        ) : (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ),
        toolTip: t('sort'),
        columnHeaderTooltip: column => `${t('sortBy')} ${column.label}`,
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCSV'),
        print: t('print'),
        viewColumns: t('viewColumns'),
        filterTable: t('filter'),
      },
      pagination: {
        next: t('nextPage'),
        previous: t('previousPage'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('of'), // 1-10 of 30
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('showColumnsAria'),
      },
    },

    filterType: 'multiselect',
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: 'none',
    customRowRender: data => {
      // let marketNameLowCase = data[10].toLowerCase();
      let currSlug = data[8]
      const handleRowClick = () => navigate(`/${lang}/currencies/${currSlug}/`)
      return (
        <TableRow onClick={handleRowClick} hover={true} key={Math.random()}>
          <TableCell style={{ textAlign: 'right' }}>
            <Link aria-label={currSlug} to={`/${lang}/currencies/${currSlug}/`}>
              <span
                style={{
                  color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
                }}
              >
                {data[0]}
              </span>
            </Link>
          </TableCell>
          <TableCell style={{ paddingRight: 0, width: 24 }}>
            {data[1]}
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            {data[2]}
          </TableCell>
          <TableCell
            style={{
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
              fontSize: 16,
            }}
          >
            {data[3]}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: ['tabular-nums'],
            }}
          >
            {currSign +
              ' ' +
              data[4].toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: ['tabular-nums'],
            }}
          >
            {data[5].toFixed(2)}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: ['tabular-nums'],
            }}
          >
            {typeof data[6] !== 'undefined' && data[6] >= 1
              ? currSign +
                ' ' +
                data[6].toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : typeof data[6] !== 'undefined' && data[6] === 0
              ? '-'
              : typeof data[6] !== 'undefined'
              ? currSign +
                ' ' +
                data[6].toLocaleString(undefined, {
                  minimumFractionDigits: 8,
                })
              : '-'}
          </TableCell>
        </TableRow>
      )
    },
    rowsPerPage: tableRows,
  }

  userBalances
    ? userBalances.map(function (item, index) {
        let currencyLogo = (
          <img
            width="20"
            height="20"
            src={
              process.env.WWW_URL +
              `/assets/img/currency/${item.balanceCurrImgName}.png`
            }
            alt={`${item.balanceCurrImgName}`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.png`
            }}
          />
        )

        let price = btcExchRate ? item.lastPrice * btcExchRate : item.lastPrice

        let balanceInUserCurr = price
          ? item.balanceAmountTotal * price
          : item.balanceAmountTotal

        return data.push(
          createData(
            index + 1,
            currencyLogo,
            item.balanceCurrCode,
            price,
            item.balanceAmountTotal,
            balanceInUserCurr,
            item.balanceCurrName,
            item.balanceCurrSlug
          )
        )
      })
    : (data = [])

  return ready && (
    <MUIDataTable
      title={
        <div>
          <Title
            color={userDarkMode ? '#3986F7' : '#185398'}
            fontSize={'28px !important'}
            lineHeight={'28px !important'}
            marginBottom={'0px !important'}
            marginTop={3}
            letterSpacing={'-0.02em'}
          >
            {t('balances')}
          </Title>
          {/* <Text variant={'small'}
                      color={userDarkMode ? "#FAFAFA" : "#191B20"}>
                      {t('currenciesIntro')}
                  </Text> */}
        </div>
      }
      data={data}
      columns={columns}
      options={options}
    />
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
          input: {
            backgroundColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)',
            paddingTop: 14,
            paddingRight: 16,
            paddingBottom: 15,
            paddingLeft: 16,
            borderRadius: 5,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          searchIcon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
          searchText: { maxWidth: 400 },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          label: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
          title: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1) !important'
                : 'rgba(0, 0, 0, 0.04) !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontFamily: 'CircularStd, sans-serif', margin: '20px 0' },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          caption: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            opacity: 0.6,
          },
          select: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: '#3377C6',
            '&:focus': { borderRadius: '10px' },
            backgroundColor: 'transparent',
          },
          selectLabel: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          displayedRows: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          selectIcon: { color: '#3377C6' },
          actions: {
            color: userDarkMode ? '#3377C6' : '#3377C6',
            backgroundColor: 'transparent',
          },
          root: { zIndex: 999 },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif' },
          paper: {
            borderRadius: 0,
            overflow: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
          },
          toolButton: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
            marginRight: '-8px',
            fontWeight: 300,
          },
          fixedHeader: {
            backgroundColor: 'transparent',
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            fontWeight: 300,
          },
          data: {
            // backgroundColor: userDarkMode ? '#191B20' : 'white' ,
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          sortActive: {
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          contentWrapper: {},
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56) !important'
              : 'rgba(0, 0, 0, 0.56) !important',
            marginTop: '3px',
            marginRight: '0',
          },
        },
      },
      MuiSelected: {
        styleOverrides: {
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': { color: '#3377C6' },
          },
        },
      },
      MuiButton: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiTableFooter: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif', fontWeight: 300 },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            minHeight: '80px !important',
            paddingLeft: '0px',
            paddingRight: '0px',
            zIndex: 999,
          },
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': { color: '#3377C6' },
          },
          titleText: {
            color: userDarkMode ? '#3986F7' : '#185398',
            fontWeight: 900,
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '24px !important',
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
          },
          actions: {
            display: 'flex',
            flex: 'initial',
            '& > span, & > button': { order: 99 },
            '& > span:last-child, & > button:last-child': { order: 1 },
            '& > span:nth-child(4), & > button:nth-child(4)': { order: 2 },
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: { headerCell: { backgroundColor: 'transparent' } },
      },
      MuiTableCell: {
        styleOverrides: {
          head: { fontWeight: 300 },
          body: {
            fontSize: '14px',
            fontFamily: 'CircularStd, sans-serif',
            cursor: 'pointer',
            color: userDarkMode ? '#FAFAFA' : 'black',
          },
          root: {
            borderBottomColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1) !important'
              : 'light',
            fontFamily: 'CircularStd, sans-serif',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontWeight: 300,
          },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(BalancesTable)
